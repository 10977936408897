


export function isKiosk(){
    return window.totDevice && typeof window.fakeTotDevice === 'undefined' ? true : false
}

export function goBack(sku) {
  
  if(window.totDevice?.goBack){
    window.totDevice.goBack(1*sku)
  }else{
    alert('goBack:' + sku)
  }
}

export function getPointNo(){
    return window.totDevice?.getPointNo()
}

export function getSkuList(defaultData){
    if( isKiosk() ){
        try {
            const rawData = window.totDevice?.getSkuList()
        if (rawData) {
          const parsedData = JSON.parse(rawData)
          return parsedData
        }
      } catch (error) {
        console.error('获取SKU列表失败:', error)
      }  
    }
    return typeof defaultData === 'undefined' ? [] : defaultData
}

export function allFunctionsAndProperties(){
  let result = []
  if(typeof window.totDevice === 'object'){
    for(i in window.totDevice){
      let memberDetail = {}
      memberDetail.name = i
      memberDetail.type = typeof window.totDevice[i]
      //dump all functions and properties
      if(typeof window.totDevice[i] === 'function'){
        memberDetail.definition = window.totDevice[i].toString()
      }else if(typeof window.totDevice[i] === 'object'){
        memberDetail.value = JSON.stringify(window.totDevice[i])
      }else{
        memberDetail.value = window.totDevice[i].toString()
      }
      result.push(memberDetail)
    }
  }
  return result
}

export function goRandom(){
    const skuList = getSkuList()
    let filtered = skuList
    if( Array.isArray(filtered) && filtered.length > 0 ) {
        filtered = filtered.filter( r=>r.stat ==1)
    }    
    if( Array.isArray(filtered) && filtered.length > 0 ) {
        const randomIndex = Math.floor(Math.random() * skuList.length)
        const randomSku = skuList[randomIndex]
        goBack(randomSku.sku)
    }
}