"use client";

import * as Sentry from "@sentry/nextjs";
import { useEffect } from 'react';
export default function GlobalError({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    // 记录错误到控制台
    Sentry.captureException(error);
    console.error('全局错误:', error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <div style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: '#fafafa'
      }}>
          <div style={{
          maxWidth: '600px',
          width: '100%',
          padding: '2rem',
          backgroundColor: '#fff1f1',
          border: '1px solid #ff0000',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
            <h1 style={{
            color: '#dc2626',
            marginBottom: '1rem',
            fontSize: '1.5rem'
          }}>
              系统错误
            </h1>

            <div style={{
            marginBottom: '1.5rem'
          }}>
              <p style={{
              color: '#666'
            }}>
                <strong>错误信息:</strong> {error.message}
              </p>
              {error.digest && <p style={{
              color: '#666',
              fontSize: '0.875rem'
            }}>
                  <strong>错误ID:</strong> {error.digest}
                </p>}
              {error.stack && <details style={{
              marginTop: '1rem'
            }}>
                  <summary style={{
                cursor: 'pointer',
                color: '#666',
                marginBottom: '0.5rem'
              }}>
                    显示详细信息
                  </summary>
                  <pre style={{
                padding: '1rem',
                backgroundColor: '#f7f7f7',
                overflow: 'auto',
                fontSize: '0.875rem',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all'
              }}>
                    {error.stack}
                  </pre>
                </details>}
            </div>

            <div style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-start'
          }}>
              <button onClick={reset} style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#3b82f6',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '0.875rem'
            }}>
                重试
              </button>
              <button onClick={() => window.location.href = '/ui/'} style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#fff',
              color: '#666',
              border: '1px solid #ddd',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '0.875rem'
            }}>
                返回首页
              </button>
            </div>
          </div>
        </div>
      </body>
    </html>;
}