"use client"

// 定义配置对象的类型
export type NestedConfig = {
  [key: string]: NestedConfig | string | number | boolean | object;
};

export const CONFIG = {
  MSG:{
    TEST: "default msg.test"
  },
  SERVICE:{
    'PB': process.env.NEXT_PUBLIC_SERVICE_PB || 'https://amcbt.singou.net/pb',
    'PAYMENT': process.env.NEXT_PUBLIC_SERVICE_PAYMENT || 'https://amcbt.singou.net/ptest',
    'RECOMMENDATION': process.env.NEXT_PUBLIC_SERVICE_RECOMMENDATION || 'http://8.138.118.60:9000',
  },
  API: {
    TIMEOUT: 10000, // 请求超时时间（毫秒）
    ENDPOINTS: {
      PB_EVENT_COLLECTOR: `/api/collections/collector/records`,
      RECOMMENDATION_RUN: `/v1/workflows/run`,
      PAYMENT_TEST: `/ptest/test_pay`,
      PB_MACHINE_STATUS: `/api/collections/VendingMachines/records`,
    },
    TOKEN: process.env.NEXT_PUBLIC_API_TOKEN || 'Bearer app-xxxxxxxxx',
    RECOMMENDATION_TOKEN: `Bearer app-uhxy4Pra8ecjiSHul3fIbgrt`,
  },
  PAGE:{
    LEFT_MENU:true,
    RIGHT_MENU:true,
  },

  // 设备相关配置
  DEVICE: {
    // 设备状态码
    STATUS: {
      AVAILABLE: 1,    // 可用
      UNAVAILABLE: 0,  // 不可用
    },
    "windowWidth": 1384,
    "windowHeight": 911,
  },

  // SKU 相关配置
  SKU: {
    // SKU 状态
    STATUS: {
      ON_SALE: 1,     // 可售
      SOLD_OUT: 0,    // 售罄
    },
    // SKU 展示配置
    DISPLAY: {
      GRID_COLS: {
        SM: 4,        // 小屏幕一列
        MD: 4,        // 中等屏幕两列
        LG: 4,        // 大屏幕三列
      }
    }
  },

  // UI 相关配置
  UI: {
    THEME_COLORS: {
      PRIMARY: '#51AE93',    // 主色
      SECONDARY: '#AE3932',  // 次要色
      SUCCESS: '#22c55e',    // 成功色
      ERROR: '#ef4444',      // 错误色
      WARNING: '#f59e0b',    // 警告色
      BACKGROUND: '#FDFDF9',  // 背景色
    },
  },
}




// 类型定义
export type ConfigType = typeof CONFIG

// 导出默认配置
export default CONFIG 

// 定义 window.env 的类型
declare global {
  interface Window {
    env?: NestedConfig;
  }
}

// 获取嵌套对象中的值
export function getValueByPath<T>(obj: NestedConfig, path: string): T | undefined {
  return path.split('.').reduce((acc: NestedConfig | undefined, key: string) => {
    if (acc === undefined) return undefined;
    return acc[key] as NestedConfig | undefined;
  }, obj) as T | undefined;
}

// 主函数
function getConfig<T>(keyPath: string): T {
  // 先从 window.env 中查找
  if(typeof window !== 'undefined'){
    const runtimeValue = getValueByPath<T>(window?.env || {}, keyPath);
    if (runtimeValue !== undefined) {
      return runtimeValue;
    }
  }
  // 如果运行时配置中没有，则从默认配置中查找
  return getValueByPath<T>(CONFIG, keyPath) as T;
}

// 使用示例:
// getConfig('api.baseUrl')
// 如果 window.env = { api: { baseUrl: 'https://dev-api.example.com' }}
// 则返回 'https://dev-api.example.com'
// 否则返回默认值 'https://api.example.com'

// 导出 getConfig 函数
export { getConfig };